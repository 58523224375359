<template>
    <standard-page title="Organizations" v-bind:definition="$DEFINITIONS.admin.orgs">
        <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'admin-home'}">Admin</router-link>
      </span>
            <span>Organizations</span>
        </template>

        <template v-slot:content>
            <data-table :api="api" :columns="columns" :row-click="view" :server-side="false" @ready="onReady" list-key="orgs" table-id="org_table">
            </data-table>
        </template>
    </standard-page>
</template>

<script>
    import EventBus from '@/event-bus';

    export default {
        name: 'Orgs',
        data() {
            return {
                api: `${this.$accounts.defaults.baseURL}/accounts/orgs`,
                columns: [
                    {type: 'name'},
                    {type: 'email'},
                    {type: 'web'},
                    {type: 'phone'},
                    {type: 'toggle', data: 'status'}
                ],
                table: null,

            };
        },
        methods: {
            onReady(table) {
                this.table = table;
                let self = this;

                $(async function () {
                    $('#org_table tbody').on('click', '.enable', function (e) {
                        e.stopPropagation();
                        let row = table.row($(this).parents('tr')).data();
                        self.enableOrg(row.org);
                        table.ajax.reload();
                    });

                    $('#org_table tbody').on('click', '.disable', function (e) {
                        e.stopPropagation();
                        let row = table.row($(this).parents('tr')).data();
                        self.disableOrg(row.org);
                        table.ajax.reload();
                    });
                });
            },
            async enableOrg(orgid) {
                try {
                    EventBus.$emit('openLoader');
                    await this.$accounts.post(`accounts/orgs/${orgid}/enable`);
                    this.table.ajax.reload();
                } catch (e) {

                } finally {
                    EventBus.$emit('closeLoader');
                }
            },
            async disableOrg(orgid) {
                try {
                    EventBus.$emit('openLoader');
                    await this.$accounts.post(`accounts/orgs/${orgid}/disable`);
                    this.table.ajax.reload();
                } catch (e) {

                } finally {
                    EventBus.$emit('closeLoader');
                }
            },
            view(org) {
                this.$router.push({name: 'vieworg', params: {orgid: org.org}});
            },
        }
    }
</script>

<style scoped>
</style>